<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 font-weight-bold flex-column" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        <div class="grey--text text--darken-3 text-uppercase">
          Ver vigencia de contratos
        </div>
        <div class="grey--text text--darken-2" :style="($vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;')">
          <b>Fecha actual</b>: {{ `${$dayjs().format('DD [de] MMMM [del] YYYY')}` }}
        </div>
      </v-card-title>
    </v-card>

<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->
    <v-card class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">

        <v-col cols="12" md="5" sm="5" xs="12"> 
          <v-select
            :label="`Consultar por...`"
            :items="vigencia_items"
            :hint="``"
            persistent-hint
            v-model="vigencia_selected"
          ></v-select>
        </v-col> 

      </v-row>
    </v-card>
<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->

    <DataTable
      id="vigencia-contratos-table"
      ref="dtable_rep_contratos"
      :headers="headers"
      :title="`Contratos`"
      :subtitle="`resultantes`"
      :items="vigenciaContratoTable.items"
      :loading="tableLoading"
      :searchEnabled="true"
      :showToolbar="true"
      :showSelect="true"
      :showExportExcel="true"
      @onRowClick="handleContratoVigenteRow"
    >
      <template v-slot:showExportExcel="{ rowValues }">
 
        <a id="exportarExcel">
          <download-excel
            :header="`${vigencia_items.find(vig => vig.value === vigencia_selected).text}`"
            :name="`Reporte_Venc_Contrato_${$dayjs().tz().format('DD_MM_YYYY-hh_mm_ss_a')}.xls`"
            :export-fields="reporte_venc_contrato_export_fields"
            :data="rowValues"
            type="xls"
            :default-value="''"
          >
            Exportar a Excel
          </download-excel>
        </a>
      </template>
    </DataTable>

  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { APP_DEBUG_MODE as APP_DEBUG, CONTRATOS_VIGENCIA } from "@/utils/Constants";
import { btoaEncodeData } from "@/utils/Functions";
import { actions, getters, STORE_NAMESPACES } from "@/store";
import { getCurrentInstance, ref, watch, computed, onBeforeUnmount, onUpdated } from "vue";

export default {
  components: {
    DataTable,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, PLANES_CLIENTE } = STORE_NAMESPACES;

    onUpdated(() => {
      if (vm?.proxy?.$refs?.dtable_rep_contratos?._data?.rowValues?.length) {
        vm.proxy.$refs.dtable_rep_contratos._data.rowValues = [];
      }
    });
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[Reporte_Contratos]: PlanesCliente unmount => SET_DEFAULT()");

      actions[PLANES_CLIENTE].SET_DEFAULT();
    });

    const reporte_venc_contrato_export_fields = {
      "No. póliza": 'poliza',
      "Nombre del plan": 'plan_catalog_data.nombre',
      "Cliente": 'nombre_completo_cliente_vigencia',
      "Moneda": 'moneda',
      "Forma de pago": 'forma_pago',
      "Inicio contrato": {
        field: "fecha_inicial_contrato",
        callback: (value) => {
          return `${vm.proxy.$root.$dayjs(value).tz().format("DD/MM/YYYY")}`;
        }
      },
      "Fin contrato": {
        field: "fecha_fin_contrato",
        callback: (value) => {
          return `${vm.proxy.$root.$dayjs(value).tz().format("DD/MM/YYYY")}`;
        }
      },
    }

    const forma_pago_header = ref("Trimestre");
    const headers = computed(() => {
      return [
        { text: "No. póliza", value: "poliza", align: "center", sortable: true },
        { text: "Nombre del plan", value: "plan_catalog_data.nombre" },
        { text: "Cliente", value: "nombre_completo_cliente_vigencia" },
        { text: "Moneda", value: "moneda" },
        { text: "Forma de pago", value: "forma_pago" },
        { text: "Inicio contrato", value: "fecha_inicial_contrato" },
        { text: "Fin contrato", value: "fecha_fin_contrato" },
      ]
    });

    const tableLoading = getters[APP].getLoading;
    const vigencia_selected = ref(null);
    const vigenciaContratoTable = useDataTable(getters[PLANES_CLIENTE].getAll, {});

    const vigencia_items = [
      { 
        text: `Vigentes`, 
        value: CONTRATOS_VIGENCIA.VIGENTES, 
      },
      { 
        text: `Próximos a vencer (< 4 meses)`, 
        value: CONTRATOS_VIGENCIA.PROX_EN_4_MESES, 
      },
      { 
        text: `Vencidos`, 
        value: CONTRATOS_VIGENCIA.VENCIDOS, 
      }
    ];

    watch(() => vigencia_selected.value, (vigencia) => {
      const currentDate = vm.proxy.$root.$dayjs().format('YYYY-MM-DD');

      switch (vigencia) {
        case CONTRATOS_VIGENCIA.VIGENTES:
          actions[PLANES_CLIENTE].FETCH_ALL_VIGENTES(currentDate);
          break;
          
        case CONTRATOS_VIGENCIA.PROX_EN_4_MESES:
          const currentDateWithAddedMonths = 
            vm.proxy.$root.$dayjs(currentDate).add(4, "months").format('YYYY-MM-DD');
          
          APP_DEBUG_MODE && console.log(currentDate, currentDateWithAddedMonths);
          actions[PLANES_CLIENTE].FETCH_ALL_VIGENTES_IN_BETWEEN(currentDate, currentDateWithAddedMonths);
          break;

        case CONTRATOS_VIGENCIA.VENCIDOS:
          actions[PLANES_CLIENTE].FETCH_ALL_VENCIDOS(currentDate);
          break;
      }
    }, { immediate: false });
    
    function handleContratoVigenteRow(contrato) {
      delete contrato.nombre_completo_cliente_vigencia;

      vm.proxy.$root.$router.push({
        path: `clientes/${contrato.id_cliente}/planes`,
        query: { viewPlanCliente: true, q: btoaEncodeData(contrato) }
      });
    }

    return {
      reporte_venc_contrato_export_fields,
      headers,

      vigencia_selected,
      vigencia_items,
      vigenciaContratoTable,

      handleContratoVigenteRow,
      
      tableLoading,
    };
  }
};
</script>

<style lang="scss">

#vigencia-contratos-table {
  tbody tr {
    cursor: pointer;
  }
}
  
</style>